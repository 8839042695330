/*start*/

@font-face {
  font-family: 'AroL';
  src: url('../src/assets/fonts/Aeonik-Light.otf') format('opentype');
}

@font-face {
  font-family: 'AroR';
  src: url('../src/assets/fonts/Aeonik-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'AroM';
  src: url('../src/assets/fonts/Aeonik-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'AroFL';
  src: url('../src/assets/fonts/AeonikFono-Light.otf') format('opentype');
}

@font-face {
  font-family: 'AroFR';
  src: url('../src/assets/fonts/AeonikFono-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'AroFM';
  src: url('../src/assets/fonts/AeonikFono-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'DruM';
  src: url('../src/assets/fonts/DrukWide-Medium-Trial.otf') format('opentype');
}


:root {
  --org: #EB6519;
  --BL: #000000;
  --w: #fff;
  --br: #4D4D4D;
  --gr: #666666;
  --gr2: #E3E1E1;
  --BG: #1E1E1C;
  --tran_s: all ease-in-out 0.5s;
}

body {
  font-family: 'AroR';
  line-height: 20px;
  font-size: 14px;
  color: var(--gr);
  background: var(--BG);
}

html {
  overflow-x: hidden;
}


/*start*/


/*custom*/
.mb-3 {
  margin-bottom: 1.2rem !important;
}

a {
  color: var(--org);
  text-decoration: none;
}

label {
  font-weight: normal;
  color: var(--gr);
  margin-bottom: 5px;
  font-family: "AroR";
  font-size: 15px;
}

a:hover,
a:focus {
  color: var(--org);
  text-decoration: none;
}

.container-fluid {
  padding: 0 15px;
}

.img_r {
  max-width: 100%;
  max-height: 100%;
}

.ul_set {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  font-weight: normal;
  margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'DruM';
}

b,
strong {
  font-weight: normal;
  font-family: "DruM";
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 15px;
}

h5 {
  font-size: 14px;
}

/*custom*/

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.btn_theme {
  background: var(--org);
  color: var(--w) !important;
  box-shadow: none !important;
  padding: 6px 20px;
  font-size: 16px;
  font-family: "AroFM";
}

.btn_theme:hover,
.btn_theme:focus,
.btn_theme:active {
  background: var(--org) !important;
  color: var(--w) !important;
  opacity: 0.9;
  border-color: var(--org) !important;
}

.btn_r {
  border-radius: 40px;
}

.btn_clr {
  padding: 0;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.form-control {
  border-radius: 6px;
  height: 45px;
  padding: 8px 15px;
  box-shadow: none !important;
  font-size: 15px !important;
  color: var(--gr2);
  font-family: "AroFM";
  border: 1px solid var(--br) !important;
  background-color: transparent !important;
}

select.form-control {
  height: 45px !important;
  background-image: url(../src/assets/img/select_arrow.png);
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 33px;
}

textarea.form-control {
  height: 100px;
}

.form-control:focus {
  border-color: var(--org) !important;
  color: var(--gr2);
}

.btn-lg {
  padding: 10px 20px;
  font-family: "AroFM";
  font-size: 18px;
  border-radius: 12px;
}

.btn-md {
  padding: 6px 15px;
  font-size: 16px;
  font-family: "DruM";
}

.btn-sm {
  padding: 4px 10px;
  font-size: 14px;
}

.btn_r {
  border-radius: 40px;
}

.text_u {
  text-transform: uppercase;
}

.btn-block {
  width: 100%;
}

.login_img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  /* background: url(../src/assets/img/bg1.png);
  background-size: cover;
  background-position: bottom; */
}

.conten_set h4,
.conten_set h1,
.conten_set h2,
.conten_set h3 {
  margin-bottom: 20px;
  color: var(--gr2);

}

.conten_set p {
  color: var(--gr);
  font-size: 15px;
}

.conten_set ul {
  margin-bottom: 20px;
  color: var(--gr);
}

.conten_set ul li {
  margin-bottom: 10px;
}

.conten_set ul.check li {
  position: relative;
  padding-left: 30px;
  font-size: 15px;
}

.conten_set ul.check li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 18px;
  height: 18px;
  border: 2px solid var(--org);
  border-radius: 100%;
}

/* login */
.login_box2.conten_set h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 28px
}

.login_box2.conten_set h2 span {
  display: block;
  font-size: 18px;
  font-family: "AroR";
  color: var(--gr);
  margin-top: 12px;
}

.login_box1 {
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
}

.login_box1 .form-control {
  height: 55px;
}

.login_box1 .forget {
  font-size: 14px;
  text-align: right;
  margin-top: 10px;
}

.login_box1 .bott_line {
  margin: 0;
  color: var(--gr);
  margin-top: 15px;
  text-align: center;
}

.login_box1 .btn {
  /* border-radius: 0; */
  padding: 10px 20px;
}

.login_box1 .bott_line a {
  font-family: "DruM";
}

.logo_login {
  width: 215px;
  /* margin-bottom: 5px; */
}

/* login */

/* sign_up */
.login_box1.sign_up h2 {
  font-size: 24px;
}

.login_box1.sign_up h2 span {
  font-size: 17px;
}

.login_box1 textarea.form-control {
  height: 90px;
}

/* sign_up */

/* otp */

.otp_nam .row {
  margin: 0 -7px;
}

.otp_nam .row .col-3 {
  padding: 0 7px;
}

.otp_nam .row .col-3 input {
  text-align: center;
}

/* otp */

.wrap_us {
  padding: 30px 25px;
  padding-left: 275px;
}

.card_me {
  background: var(--BL);
  border: 1px solid var(--br);
  border-radius: 12px;
}

.card_body {
  padding: 15px
}

.row {
  margin: 0 -10px;
}

.row [class*="col-"] {
  padding: 0 10px;
}



.check_b {
  position: relative;
  width: 25px;
  display: inline-block;
}

.check_b input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0.01;
  cursor: pointer;
}

.check_b span {
  width: 100%;
  height: 14px;
  background: var(--gr2);
  display: inline-block;
  border-radius: 40px;
  position: relative;
}

.check_b span::before {
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  left: 2px;
  top: 2px;
  background: var(--BL);
  border-radius: 100%;
  transition: all ease-in-out 0.5s;
  content: "";
}

.check_b input:checked~span::before {
  left: 13px;
}

.check_b input:checked~span {
  background: var(--org);
}

.btn_us1 .btn_clr img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.btn_us1 span {
  vertical-align: middle;
}

.box_1 h6 {
  font-size: 14px;
}

.box_1 h4 {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  word-break: break-all;
}

.box_1 h4 .btn_us1 {
  max-width: 71px;
  flex: 0 0 71px;
  text-align: right;
  margin-left: 10px;
}

.box_1 .row {
  margin: 0 -5px
}

.box_1 [class*="col-"] {
  padding: 0 5px
}

.box_1 .form-control {
  border: none !important;
  border-bottom: 1px solid var(--gr2) !important;
  border-radius: 0;
  height: 30px;
  padding: 5px 5px;
}

.btn_us1 .btn {
  margin-left: 6px;
}

.box_2 .row {
  margin: 0;
}

.box_2 [class*="col-"] {
  padding: 20px 15px
}

.box_2 .row .col-md-4+.col-md-4 {
  border-left: 1px solid var(--br);
}

.btn_us2 {
  margin-top: 30px;
  text-align: center;
}

.btn_us2 .btn {
  width: 85%;
}

.hr {
  margin: 22px -25px;
}

.hr hr {
  color: var(--br);
  opacity: 1;
  margin: 0;
}

.table_des1 {
  overflow: hidden;
  padding: 0;
}


.table_des1 tr th,
.table_des1 tr td {
  border-bottom: 1px solid var(--br) !important;
  font-size: 14px;
  color: var(--gr2);
  font-weight: normal;
  font-family: "AroFM";
  background: transparent;
}


.table_des1 tbody tr:nth-child(1) td {
  border-top: 2px solid var(--br) !important;
}

.table_des1 tr th {
  font-size: 14px;
  font-family: "AroL";
  border: none !important;
}

.table_des1 .table {
  margin-bottom: 0;
}

.footer_tab {
  display: flex;
  justify-content: space-between;
  padding: 16px 15px;
}

.table_des1 .form-control {
  height: 40px;
}

.icon_droup {
  position: relative;
  padding: 0 16px;
}

.icon_droup img {
  width: 10px;
  position: absolute;
  left: 0;
  top: 10px;
  cursor: pointer;
}

.icon_droup input~img {
  right: 0;
  left: auto;
}

.icon_droup input {
  width: 70px;
  font-family: "DruM";
  text-align: center;
}

.left_t1,
.right_t1 {
  display: flex;
  align-items: center;
}

.left_t1 p,
.right_t1 p {
  color: var(--gr2);
  margin-bottom: 0;
  margin-left: 10px;
}

/* header */

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--BL);
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid var(--br);
}

.logo {
  display: inline-block;
  padding: 24px 25px;
  border-right: 1px solid var(--br);
  width: 250px;
  text-align: center;
}

.logo img {
  width: 130px;
}

.left_lu h4 {
  font-size: 16px;
  margin: 0;
  padding-left: 25px;
  color: var(--gr2);
}

.left_lu {
  display: flex;
  align-items: center;
}

.user_img {
  font-size: 14px;
  width: 35px;
  height: 35px;
  display: inline-block;
  background: var(--br);
  border-radius: 100%;
  text-align: center;
  line-height: 35px;
  font-family: "AroR";
  color: var(--w);
}

.dropdown.user_profile a {
  font-size: 17px;
  font-family: "AroR";
  color: var(--w);
  padding-left: 45px;
  display: inline-block;
}

.dropdown.user_profile a .user_img {
  position: absolute;
  left: 0;
}

.dropdown.user_profile a .email_h {
  font-size: 14px;
  display: block;
}

.dropdown.user_profile a::after {
  display: none;
}

.right_mennu {
  padding: 15px 25px;
  border-left: 1px solid var(--br);
}

.top_aseh {
  padding: 16px 15px;
  display: flex;
  justify-content: space-between;
}

.searh_m2 {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
  color: var(--gr2);
}

.searh_m2 .form-control {
  margin-left: 15px;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--gr2);
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--gr2);
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--gr2);
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: var(--gr2);
}

.top_aseh h4 {
  color: var(--gr);
}

.table_des1 tr th.orderd {
  position: relative;
  padding-right: 30px;
}

.table_des1 tr th.orderd::after,
.table_des1 tr th.table-down::after {
  position: absolute;
  right: 8px;
  top: 15px;
  content: "";
  width: 11px;
  height: 14px;
  background: url(../src/assets/img/down_icon.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.table_des1 tr th.table-up::after {
  background: url(../src/assets/img/up_icon.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.slidebar {
  position: fixed;
  left: 0;
  width: 250px;
  background: var(--BL);
  border-right: 1px solid var(--br);
  border-top: 1px solid var(--br);
  height: calc(100% - 70px);
  top: 70px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;
}

.slidebar li>a {
  font-size: 16px;
  padding: 14px 15px;
  display: inline-block;
  width: 100%;
  color: var(--gr2) !important;
  transition: var(--tran_s);
  position: relative;
  padding-left: 45px;
}

.slidebar li>a svg {
  font-size: 16px;
  position: absolute;
  left: 15px;
  top: 18px;
}

.slidebar li>a:hover,
.slidebar li>a.active {
  background: var(--org);
  color: var(--w);
}

.fusioncharts-container .fusioncharts-svg line,
.fusioncharts-container .fusioncharts-svg path {
  shape-rendering: auto !important;
  /* Reset shape-rendering property */
}


.ReactModal__Content {
  background: var(--BL) !important;
  border: 1px solid var(--br) !important;
  transform: initial !important;
  margin: 0 auto !important;
  inset: initial !important;
  left: 0 !important;
  right: 0 !important;
  margin-top: 40px !important;
}

.ReactModal__Overlay {
  z-index: 999;
  overflow: auto;
  backdrop-filter: blur(8px);
}

g.raphael-group-MtqVMwhW {
  display: none !important;
}

.chart_d>div {
  border: 1px solid var(--br);
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;
}

.men_side {
  width: 25px;
  position: relative;
  height: 18px;
  cursor: pointer;
  display: inline-block;
  top: 2px;
  margin-right: 10px;
}

.men_side span {
  display: inline-block;
  width: 100%;
  height: 2px;
  background: var(--gr2);
  position: absolute;
  left: 0;
  transition: all ease-in-out 0.3s;
}

.men_side span:nth-child(1) {
  top: 0;
  width: 80%;
}

.men_side span:nth-child(2) {
  width: 100%;
  top: 7px;
}

.men_side span:nth-child(3) {
  top: 14px;
  width: 60%;
}

.men_side.open_mm span:nth-child(1) {
  width: 55%;
  transform: rotate(45deg);
  left: 13px;
  top: 3px;
}

.men_side.open_mm span:nth-child(3) {
  width: 55%;
  transform: rotate(-45deg);
  left: 13px;
  top: 12px;
}

.tab_lisd1 {
  background: var(--BL);
  border-radius: 40px;
  padding: 4px;
  align-items: center;
  border: 1px solid var(--br);
  position: relative;
}

.tab_lisd1.profil_tab {
  padding-right: 250px;
}

.tab_lisd1 button {
  background: transparent;
  border: none !important;
  color: var(--gr);
  outline: none;
  font-size: 16px;
  padding: 11px 15px;
  border-radius: 40px;
  font-family: "AroFM";
}

.tab_lisd1 button[style*="border-bottom: 2px"] {
  background: var(--org);
  color: var(--w);
}

.form-control option {
  background: var(--BL);
}

.profliedd {
  position: relative;
  font-size: 32px;
  width: 90px;
  height: 90px;
  display: inline-block;
  background: var(--br);
  border-radius: 100%;
  text-align: center;
  line-height: 90px;
  font-family: "AroR";
  color: var(--w);
}

.user_pro {
  text-align: center;
}

.user_pro h4 {
  font-family: "AroM";
  font-size: 21px;
  margin-top: 15px;
}

.user_pro h4 span {
  display: block;
  font-family: "AroR";
  font-size: 16px;
  margin-top: 3px;
}

.table_des1 .btn {
  margin-left: 20px;
}

.table_des1 .check_b {
  margin-left: 20px;
}

.table_des1 tr th:nth-last-child(1) {
  width: 155px;
}




.tooltip {
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
}

pricing-plans {
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 64px;
}

.pricing-card {
  --col: #e4e4e7;
  position: relative;
  min-width: 360px;
  padding: 32px;
  padding-bottom: 96px;
  border-radius: 4px;
  border: 5px solid #262626;
  background-color: #26262620;
  box-shadow: 0 0 32px transparent;
  text-align: center;
  border-radius: 20px;
}

.pricing-card.basic {
  --col: #eb6519;
}

.pricing-card.standard {
  --col: #eb6519;
}

.pricing-card.premium {
  --col: #c026d3;
}

.pricing-card:hover {
  border-color: var(--col);
  background-color: #26262680;
  box-shadow: 0 0 32px #171717;
  transform: translateY(-16px) scale(1.02);
  transition: all 0.5s ease;
}

.pricing-card>*:not(:last-child) {
  margin-bottom: 32px;
}

.pricing-card .heading h4 {
  padding-bottom: 12px;
  color: var(--col);
  font-size: 24px;
  font-weight: normal;
}

.pricing-card .heading p {
  color: #a3a3a3;
  font-size: 14px;
  font-weight: lighter;
}

.pricing-card .price {
  position: relative;
  color: var(--col);
  font-size: 60px;
  font-weight: bold;
}

.pricing-card .price sub {
  position: absolute;
  bottom: 14px;
  color: #a3a3a3;
  font-size: 14px;
  font-weight: lighter;
}

.pricing-card .features li {
  padding-bottom: 16px;
  color: #a3a3a3;
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
}

.pricing-card .features li i,
.pricing-card .features li strong {
  color: #e4e4e7;
  font-size: 16px;
  text-align: left;
}

.pricing-card .features li strong {
  padding-left: 24px;
}

.pricing-card .cta-btn {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--col);
  background-color: var(--col);
  color: #e4e4e7;
  font-size: 20px;
  font-weight: bold;
}

.pricing-card .cta-btn:active {
  background-color: transparent;
  color: var(--col);
  transition: all 0.3s ease;
}

.tooltip {
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
}

.fixe_date {
  display: flex;
}

.fixe_date .From_D1,
.fixe_date .From_D2 {
  display: flex;
  align-items: center;
}

.fixe_date .From_D2 {
  margin-left: 15px;
}

.fixe_date .From_D1 label,
.fixe_date .From_D2 label {
  margin-right: 10px;
}

.fixe_date .From_D1 input,
.fixe_date .From_D2 input {
  width: 200px;
}



.plan_ds1 {
  background: url(../src/assets/img/plan1.png);
  width: 400px;
  background-size: 100%;
  color: var(--w);
  margin-left: auto;
  margin-right: auto;
}

.plan_ds1>h3 {
  margin: 0;
  font-size: 21px;
  height: 55px;
  padding: 21px 25px;
  display: flex;
  justify-content: space-between;
}

.plan_ds1>h3 span {
  font-size: 14px;
  font-family: "AroR";
  display: inline-block;
  margin-top: 5px;
}

.payment1 {
  padding: 0 25px;
  height: 83px;
}

.payment1 .row {
  margin: 0 -5px;
}

.payment1 .row .col-6 {
  padding: 0 5px;
}

.payment1 h4 {
  margin-bottom: 9px;
  height: 49px;
  font-size: 40px;
  text-align: center;
  padding-top: 5px;
  color: var(--BG);
}

.payment1 h6 {
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-size: 12px;
}

.payment1 h6 span {
  display: inline-block;
  padding: 5px 10px;
  background: var(--BG);
  border-radius: 6px 6px 0 0;
  height: 25px;
  overflow: hidden;
}

.payment1 h6 span.pd2 {
  font-size: 12px;
  font-family: "AroR";
}

.plan_ds1 h5 {
  height: 74px;
  text-align: center;
  padding-top: 11px;
  font-size: 21px;
  margin: 0;
  line-height: 24px;
}

.plan_ds1 h5 span {
  display: block;
  font-size: 13px;
}


.plan_ds1.plan_ds2 {
  background: url(../src/assets/img/plan2.png);
  background-size: 100%;
}

.plan_ds1.plan_ds2>h3 {
  height: 129px;
  justify-content: center;
  padding: 0 25px;
  padding-top: 80px;
  font-size: 35px;
}

.plan_ds1.plan_ds2 h5 {
  height: 95px;
  padding-top: 25px;
}

.plan_ds1.plan_ds2>h3.time_one {
  padding-top: 10px;
  height: 80px;
}

.plan_ds1.plan_ds2 .payment1 .row .col-6:nth-child(1) h4,
.plan_ds1.plan_ds3 .payment1 .row .col-6:nth-child(1) h4 {
  text-align: right;
}

.plan_ds1.plan_ds2 .payment1 .row .col-6:nth-child(2) h4,
.plan_ds1.plan_ds3 .payment1 .row .col-6:nth-child(2) h4 {
  text-align: left;
  padding-left: 3px;
}

.plan_ds1.plan_ds3 {
  background: url(../src/assets/img/plan3.png);
  background-size: 100%;
}

.plan_ds1.plan_ds3>h3 {
  height: 130px;
  justify-content: center;
  padding: 0 25px;
  padding-top: 75px;
  font-size: 36px;
}

.plan_ds1.plan_ds3 h5 {
  height: 85px;
  padding-top: 25px;
}

.plan_ds1.plan_ds3>h3.time_one {
  padding-top: 10px;
  height: 105px;
}

.payment1 .row .col-6:nth-child(2) h6 {
  padding-left: 3px;
}

.payment1 .row .col-6:nth-child(1) h6 {
  justify-content: right;
}


.plan_ds1.plan_custum {
  background: url(../src/assets/img/plan4.png);
  background-size: 100%;
}

.plan_ds1.plan_custum>h3 {
  font-size: 28px;
  padding-top: 92px;
}

.plan_custum .payment1 .row .col-6:nth-child(2) {
  position: relative;
  padding-left: 52px;
  padding-top: 12px;
}

.plan_custum .payment1 .evey_cut {
  position: absolute;
  color: var(--BG);
  font-size: 10px;
  left: 10px;
  top: 6px;
  width: 40px;
}

.plan_custum .payment1 .row .col-6:nth-child(2) .cust_us {
  position: absolute;
  top: 6px;
  left: 50px;
  font-size: 11px;
}

.plan_custum .payment1 .row .col-6:nth-child(2) .cust_us span {
  border-radius: 0 0 5px 5px;
  height: auto;
  padding: 4px 9px;
}

.plan_ds1.plan_custum .payment1 .row .col-6:nth-child(2) h4 {
  padding-left: 0;
  padding-top: 6px;
  margin-bottom: 0;
}

.plan_ds1.plan_custum .payment1 .row .col-6:nth-child(1) h4 {
  margin-bottom: 10px;
  text-align: center;
  padding-top: 8px;
}

.plan_ds1.plan_custum .payment1 .row .col-6:nth-child(1) h6 span {
  min-width: 80%;
}

.plan_custum .payment1 .evey_cut span {
  padding-top: 10px;
  display: inline-block;
}

.plan_custum .payment1 h4 {
  height: 57px;
}

.form-check-input:checked {
  background-color: var(--org);
  border-color: var(--org);
}

.swdd2.form-check-inline .form-check-label {
  margin-right: 5px;
}

.swdd2.form-check-inline .btn_us1 {
  vertical-align: top;
}

.not_found {
  text-align: center;
  width: 100%;
  display: inline-block;
  padding: 10px;
  max-width: 100%;
  margin: 0 auto;
  font-size: 18px;
}

#button-tooltip {

  padding: 0px;
}

.tooltip>div.tooltip-inner {
  background-color: var(--org) !important;
  color: var(--w) !important;
  padding: 5px;
}

.tooltip.show {
  opacity: 1 !important;
}

.chart_des svg>g+g {
  display: none;
}

.heade_close {
  position: relative;
}

.heade_close .btn {
  width: 35px;
  height: 35px;
  padding: 0;
  border-radius: 100%;
  line-height: 33px;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 0;
}

/* .thumbnail-image {
  height: 120px;
} */

.thumbnail-image {
  position: relative;
  padding: 10px;
  padding-left: 125px;
}

.thumbnail-image .react-thumbnail-generator {
  position: absolute;
  left: 10px;
  width: 100px;
  height: 85px;
}

.thumbnail-image .react-thumbnail-generator img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vide_full video {
  width: 100% !important;
  height: 600px !important;
  object-fit: contain;
}

.thumbnail-image h4 {
  font-size: 18px;
  font-family: 'AroR';
  color: var(--w);
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thumbnail-image p {
  font-size: 14px;
  margin-bottom: 1px;
}

.thumbnail-image {
  position: relative;
  padding: 10px;
  padding-left: 125px;
  border-bottom: 1px solid var(--br);
}

.card_me.thum_list {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 0;
}

.gallery .col-md-9 {
  width: calc(100% - 400px);
}

.gallery .col-md-3 {
  width: 400px;
}

.thum_list .ul_set {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 5px;
}

.thum_list .ul_set li {
  padding: 0 5px;
  width: 16.66%;
  flex: 0 0 auto;
  text-align: center;
}

.thum_list .ul_set li .btn {
  width: 45px;
  height: 45px;
  white-space: nowrap;
  padding: 5px;
  border: 1px solid var(--br);
  border-radius: 100%;
  color: var(--gr);
}

.thum_list .ul_set li .btn span {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 100%;
  padding-top: 5px;
}

.thum_list .ul_set li.active .btn span {
  background: var(--org);
  color: var(--w);
}

.arrow_act {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow_act .btn {
  color: var(--org);
  font-size: 19px;
}

.star_ded {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 15px;
}


/* loader */
.main_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  z-index: 99999;
}

.loader-11 {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent transparent;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

.loader-11:after,
.loader-11:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #EB6519 #EB6519;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-animation: rotationBack 0.5s linear infinite;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.loader-11:before {
  width: 32px;
  height: 32px;
  border-color: #fff #fff transparent transparent;
  -webkit-animation: rotation 1.5s linear infinite;
  animation: rotation 1.5s linear infinite;
}




@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes rotationBreak {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBreak {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* loader */

/* 
.daterangepicker .calendar-table tr th,
.daterangepicker .calendar-table tr td {
  background: transparent !important;
  color: var(--gr);
  border-radius: 4px;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {

  color: var(--w);
}

.daterangepicker .calendar-table td.active,
.daterangepicker .calendar-table td.active:hover {
  background: var(--org) !important;
  color: var(--w) !important;
} */


.daterangepicker {
  background: var(--BL);
  border: 1px solid var(--br);
}

.daterangepicker::before {
  border-bottom: 7px solid var(--br);
}

.daterangepicker::after {
  border-bottom: 6px solid var(--br);
}

.daterangepicker .calendar-table {
  background: transparent;
  border: none;
}

.daterangepicker .calendar-table tr th,
.daterangepicker .calendar-table tr td {
  border-radius: 0 !important;
}

.daterangepicker .calendar-table tr td {
  color: #b7b7b7;
}

.daterangepicker .calendar-table tr td.ends {
  background: transparent;
  color: #6c6c6c;
}

.daterangepicker td.in-range {
  background-color: var(--br);
  color: var(--w);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--org);
  color: var(--w) !important;
}

.daterangepicker .applyBtn {
  background: var(--org);
  border-color: var(--org);
}

.daterangepicker .cancelBtn {
  border: 1px solid var(--br);
  color: var(--gr);
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: var(--org) !important;
  border-color: var(--org);
}

.daterangepicker .calendar-table tr th.month {
  color: var(--w);
  font-weight: 600;
  font-size: 15px;
}

.daterangepicker .calendar-table tr th {
  color: var(--w);
}

.fixe_date.exp_file {
  align-items: center;
}

.fixe_date.exp_file .btn {
  margin-left: 10px;
  background: var(--gr);
  color: var(--w);
  border-color: var(--gr);
}


.chart_des svg g g rect {
  fill: var(--BL);
  stroke: var(--BL);
}

.white_us {
  white-space: nowrap;
}

.top_aseh.justify-content-end-back {
  position: absolute;
  right: 30px;
}


.flex_static {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_static span {
  display: flex;
  align-items: center;
}

.flex_static label {
  margin-right: 10px;
}

.flex_static .form-control {
  height: 35px !important;
  font-size: 13px !important;
  width: 115px;
}

.table_des1 .table-responsive {
  max-height: 600px;
}

.template_list_m1.mt_uss .table-responsive {
  max-height: 500px;
}

.table_des1 .table-responsive .table>thead {
  position: sticky;
  top: 0;
  background: var(--BL);
  z-index: 2;
}

.right_profile {
  position: absolute;
  right: 20px;
}

/* tooltip */
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--org);
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--org);
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--org);
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--org);
}

/* tooltip */

/* media */

@media (min-width:992px) {
  .slidebar {
    width: 48px;
    transition: var(--tran_s)
  }

  .slidebar li>a {
    white-space: nowrap;
  }

  .slidebar li>a>span {
    opacity: 0;
  }

  .logo {
    width: 140px;
    padding: 24px 5px;
    transition: var(--tran_s)
  }

  .logo img {
    width: 100px;
    transition: var(--tran_s)
  }

  .wrap_us {
    padding-left: 75px;
    transition: var(--tran_s)
  }

  .sidebar_open .logo {
    padding: 24px 25px;
    width: 250px;
  }

  .sidebar_open .logo img {
    width: 130px;
  }

  .sidebar_open .slidebar {
    width: 250px;
  }

  .sidebar_open .slidebar li>a>span {
    opacity: 1;
  }

  .sidebar_open .wrap_us {
    padding-left: 275px;
  }
}

@media (max-width:991px) {
  .wrap_us {
    padding: 20px 15px;
  }

  .sidebar_open .slidebar {
    left: -100%;
  }

  .slidebar {
    left: 0;
    transition: var(--tran_s);
    height: calc(100% - 51px);
    top: 51px;
  }

  .men_side.open_mm span:nth-child(3) {
    /* width: 55%; */
    /* transform: rotate(-45deg); */
    /* left: 13px; */
    /* top: 12px; */
    top: 14px;
    width: 60%;
    left: 0;
    transform: initial;
  }

  .men_side.open_mm span:nth-child(1) {
    /* width: 55%; */
    /* transform: rotate(45deg); */
    /* left: 13px; */
    /* top: 3px; */
    top: 0;
    width: 80%;
    left: 0;
    transform: initial;
  }

  .men_side span:nth-child(3) {

    width: 55%;
    transform: rotate(-45deg);
    left: 13px;
    top: 12px;
  }

  .men_side span:nth-child(1) {
    width: 55%;
    transform: rotate(45deg);
    left: 13px;
    top: 3px;
  }

  .logo {
    display: none;
  }



  .dropdown.user_profile>a {
    font-size: 0;
    padding: 0;
  }

  .dropdown.user_profile a .email_h {
    display: none;
  }

  .dropdown.user_profile a .user_img {
    position: initial;
  }

  .right_mennu {
    padding: 8px 12px;
    border: none;
  }
}

@media (max-width:767px) {

  /* comman */
  h4 {
    font-size: 16px;
  }

  .conten_set h4,
  .conten_set h1,
  .conten_set h2,
  .conten_set h3 {
    margin-bottom: 15px;
    color: var(--gr2);
  }

  h3 {
    font-size: 18px;
  }

  .ReactModal__Content {
    min-width: initial !important;
  }

  .btn_us1 {
    white-space: nowrap;
  }

  .table_des1 .check_b {
    margin-left: 10px;
  }

  .table_des1 tr th {
    white-space: nowrap;
  }

  .table_des1 tr th,
  .table_des1 tr td {
    font-size: 13px;
  }

  .hr {
    margin: 20px -15px;
  }

  .tab_lisd1 {
    flex-wrap: wrap;
    border-radius: 15px;
    padding: 10px;
  }

  .tab_lisd1.profil_tab {
    padding-right: 10px;
    padding-bottom: 80px;
  }

  .right_profile {
    right: auto;
    bottom: 10px;
    left: 0;
    border-top: 1px solid var(--br);
    width: 100%;
    padding: 10px;
    padding-bottom: 3px;
  }

  .tab_lisd1 button {
    padding: 7px 13px;
    font-size: 14px;
    border-radius: 4px;
  }

  .searh_m2 {
    margin-top: 15px;
  }

  /* comman */
  .table_des1 .btn {
    margin-left: 10px;
  }

  .btn_theme {

    padding: 6px 15px;
    font-size: 15px;
  }

  .col-md-6.chart_d>div {
    height: 350px;
  }

  .form-control {
    height: 40px;
    font-size: 14px !important;
  }

  .col-md-6.chart_d>div>span {
    height: 100% !important;
  }


  .left_lu h4 {
    font-size: 14px;
  }

  .flex_static {
    flex-wrap: wrap;
  }

  .flex_static span {
    margin-top: 10px;
    margin-left: auto;
  }

  .box_1 h4 {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .gallery .col-md-9 {
    width: 100%;
  }

  .gallery .col-md-3 {
    width: 100%;
  }

  .vide_full video {
    height: 250px;
  }

  .card_me.thum_list {
    height: 300px;
    margin-top: 20px;
  }

  .profliedd {
    height: 75px;
    width: 75px;
    line-height: 75px;
    font-size: 28px;
  }

  .user_pro h4 {
    font-size: 18px;
  }

  .user_pro h4 span {
    font-size: 14px;
  }

  .top_aseh {
    flex-wrap: wrap;
  }

  .fixe_date.exp_file {
    flex-wrap: wrap;
  }

  .fixe_date.exp_file .btn {
    margin-left: auto;
    margin-top: 15px;
  }

  .plan_ds1 {
    width: 320px;
  }

  .plan_ds1>h3 {
    font-size: 13px;
    height: 46px;
    padding: 0 22px;
    padding-top: 20px;
  }

  .plan_ds1>h3 span {
    margin-top: 3px;
    font-size: 12px;
    font-family: "AroL";
  }

  .payment1 h4 {
    height: 33px;
    font-size: 23px;
  }

  .plan_ds1.plan_ds3 h5 {
    height: 60px;
    padding-top: 10px;
  }

  .payment1 h6 span {
    font-size: 10px;
    font-family: "AroR";
    padding: 2px 8px;
    height: 22px;
  }

  .payment1 {
    padding: 0 25px;
    height: 65px;
  }

  .plan_ds1 h5 {
    font-size: 12px;
    line-height: 19px;
    padding-top: 8px;
    height: 59px;
  }

  .plan_ds1 h5 span {
    font-size: 12px;
    font-family: 'AroR';
  }

  .plan_ds1.plan_ds2>h3 {
    font-size: 20px;
    padding-top: 60px;
    height: 106px;
  }

  .plan_ds1.plan_ds2 h5 {
    height: 65px;
    padding-top: 15px;
  }

  .plan_ds1.plan_ds3>h3 {
    font-size: 25px;
    padding-top: 55px;
    height: 108px;
  }

  .plan_ds1.plan_ds3>h3.time_one {
    height: 85px;
  }

  .daterangepicker .drp-buttons .btn {
    margin-left: 5px;
  }

  .left_lu h4 {
    padding-left: 15px;
  }

  .slidebar li>a {
    font-size: 14px;
    padding: 10px 15px;
    padding-left: 35px;
  }

  .slidebar li>a svg {
    font-size: 15px;
    top: 14px;
    left: 10px;
  }

  .slidebar li {
    margin-bottom: 2px;
  }

  .card_me.box_1 {
    margin-bottom: 20px;
  }

  .box_2 .row .col-md-4+.col-md-4 {
    border-top: 1px solid var(--br);
    border-left: none;
  }

  .heade_close .btn {
    width: 28px;
    height: 28px;
    line-height: 26px;
    font-size: 16px;
  }
}

/* media */
.thum_list .ul_set li.active .blank span {
  background: #ffffff;
  color: #000000;
}

.vide_full h3 {
  padding: 24.80%;
  text-align: center;
}

.icon_eyeyy {
  position: relative;
}

.eye_fmin {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.user_profile_img {
  font-size: 14px;
  width: 90px;
  height: 90px;
  display: inline-block;
  background: var(--br);
  border-radius: 100%;
  text-align: center;
  line-height: 35px;
  font-family: "AroR";
  color: var(--w);
}

button.btn.videoBorder {
  border: 2px solid #eb6519 !important;
  color: #fff !important;
}

button.btn.blank.empty {
  background-color: #fff;
}